export const environment = {
    apiPrefix: '/api',
    appName: "VolkerWessels Beeldbank",
    appNameShort: "Beeldbank",
    azureClientId: `db4d987b-f88f-4eea-85a0-fc6ab6b299b9`,
    azureLogoutUri: `https://beeldbank.volkerwessels.com/`,
    azureReadApi: `api://db4d987b-f88f-4eea-85a0-fc6ab6b299b9/Read`,
    azureRedirectUri: `https://beeldbank.volkerwessels.com/`,
    maxDocumentSizeMB: 20,
    loginDescription: "Klik hieronder op inloggen om in te loggen met uw VolkerWessels account.",
    missingRoles: "U heeft nog geen toegang tot de Beeldbank. Neem contact op met uw beheerder.",
    production: true,
    showLoginShape: false,
    useMock: false,
    applicationScope: null,
    featureToggles: [],
    vistaCreateApiKey: null,
    roleLabels: {
        'ROLE_COMMUNICATION_OFFICER': 'Communication officer',
        'ROLE_ORGANIZATION_ADMIN': 'Organisatie-admin',
        'ROLE_USER_MANAGER': 'Gebruikersbeheerder',
        'ROLE_MARKETING': 'Marketing',
        'ROLE_SUPER_ADMIN': 'IT-admin',
    }
};
